import React from 'react'
import { Container, Row} from "react-bootstrap";
import Seo from '../seo'
import Cards from '../Blocks/Cards';
import Breadcrumbs from "../Blocks/Breadcrumbs"
import Features from "../Blocks/Features"

function Services({localImages, pages, ...props}) {

  console.log('propsServices', props)
  const services = pages.filter(page => page.type === 'service')
  
    return (
      <>
      <Seo
          title={props.content.seoTitle || props.content.h1}
          description={props.content.seoDescription}
          metas={props.global.defaults.metas}
      />
        <Breadcrumbs home={props.global.defaults.lang.home} contentH1={props.content.h1}/>
      <Container style={{maxWidth: 1280, minHeight: 500}}>
        <div id='h1p'>
          <h1 style={{textAlign: 'center'}}>{props.content.h1}</h1>
          <p style={{textAlign: 'center'}}>{props.content.subTitle || ''}</p>
        </div>
        <Row>
          {props.content.clickable === true ? 
          <Cards pages={services} localImages={localImages}/>
          :
          // <div dangerouslySetInnerHTML={{__html: props.content.text}} />
          <Features
          // title={features.title}
          // subTitle={features.subTitle}
          title1={props.content.service1}
          icon1={props.content.serviceIcon1}
          title2={props.content.service2}
          icon2={props.content.serviceIcon2}
          title3={props.content.service3}
          icon3={props.content.serviceIcon3}
          title4={props.content.service4}
          icon4={props.content.serviceIcon4}
          title5={props.content.service5}
          icon5={props.content.serviceIcon5}
          title6={props.content.service6}
          icon6={props.content.serviceIcon6}
          leadUrl={props.global.defaults.data.lead}
      />
          }
        </Row>
        </Container>


      </>
    )
}

export default Services