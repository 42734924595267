import React from 'react'
import { Container, Row, Button } from "react-bootstrap";
import { navigate } from 'gatsby'
import { Text } from "../../components/Core";
import {BsFillHouseDoorFill} from 'react-icons/bs'
import {MdFence} from 'react-icons/md'
import {GiFarmTractor} from 'react-icons/gi'
import { FaSwimmer, FaPaintRoller, FaTools, FaDraftingCompass, FaUserCheck, FaWater, FaTruckMonster } from "react-icons/fa";

function Services(props) {

  console.log('props', props)
  const goTo = () => {
    navigate(props.leadUrl)
  }

  const icon = (icon) => {
    if(icon === 'BsFillHouseDoorFill') return (<BsFillHouseDoorFill style={{color: '#413e65', marginTop: 4, marginLeft: 0}}/>)
    if(icon === 'FaSwimmer') return <FaSwimmer style={{color: '#413e65', marginTop: 4, marginLeft: 0}}/> 
    if(icon === 'FaPaintRoller') return (<FaPaintRoller style={{color: '#413e65', marginTop: 4, marginLeft: 0}}/>)
    if(icon === 'FaTools') return (<FaTools style={{color: '#413e65', marginTop: 4, marginLeft: 0}}/>)
    if(icon === 'FaDraftingCompass') return (<FaDraftingCompass style={{color: '#413e65', marginTop: 4, marginLeft: 0}}/>)
    if(icon === 'FaUserCheck') return (<FaUserCheck style={{color: '#413e65', marginTop: 4, marginLeft: 0}}/>)
    if(icon === 'FaWater') return (<FaWater style={{color: '#413e65', marginTop: 4, marginLeft: 0}}/>)
    if(icon === 'FaTruckMonster') return (<FaTruckMonster style={{color: '#413e65', marginTop: 4, marginLeft: 0}}/>)
    if(icon === 'GiFarmTractor') return (<GiFarmTractor style={{color: '#413e65', marginTop: 4, marginLeft: 0}}/>)
    if(icon === 'MdFence') return (<MdFence style={{color: '#413e65', marginTop: 4, marginLeft: 0}}/>)
 }


    return (
        <div>
    {props.title ? 
      <Container fluid style={{paddingTop: 130, paddingBottom: 130, marginBottom: 130, position: 'relative', zIndex: 5, background: '#f7f7fb'}}>
      
      <div style={{textAlign: 'center', marginBottom: 50}}>
      <h2 style={{marginBottom :20}}>{props.title}</h2>
      <Text style={{marginBottom : 45}}>{props.subTitle}</Text>
      </div>
     
        <Row style={{ maxWidth: 1280, margin: 'auto'}} data-aos="fade-up" data-aos-duration="750">
           
              
                  <p className="lead" style={{textAlign: 'center', margin: 'auto', marginBottom: 20, marginTop: 20}}>
                    
                  <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                  <div style={{backgroundColor: 'rgba(65, 62, 101, 0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                    {icon(props.icon1)}
                    {/* <FontAwesomeIcon icon={props.icon1} style={{color: '#413e65', marginTop: 14, marginLeft: 1}} /> */}
                    </div>
                  <h3 style={{fontFamily: 'Montserrat', color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title1}</h3>
                  </Button>

                  <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                  <div style={{backgroundColor: 'rgba(65,62,101,0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                  {icon(props.icon2)}
                    </div>
                  <h3 style={{fontFamily: 'Montserrat',color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title2}</h3>
                  </Button>
                  <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                  <div style={{backgroundColor: 'rgba(65, 62, 101, 0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                  {icon(props.icon3)}
                    </div>
                  <h3 style={{fontFamily: 'Montserrat',color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title3}</h3>
                  </Button>
                  <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                  <div style={{backgroundColor: 'rgba(65, 62, 101, 0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                  {icon(props.icon4)}
                    </div>
                  <h3 style={{fontFamily: 'Montserrat',color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title4}</h3>
                  </Button>
                  <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                  <div style={{backgroundColor: 'rgba(65, 62, 101, 0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                  {icon(props.icon5)}
                    </div>
                  <h3 style={{fontFamily: 'Montserrat',color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title5}</h3>
                  </Button>
                  <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                  <div style={{backgroundColor: 'rgba(65, 62, 101, 0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                  {icon(props.icon6)}
                    </div>
                  <h3 style={{fontFamily: 'Montserrat',color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title6}</h3>
                  </Button>
    
                  </p> 
          
              </Row>
 
      </Container>
       : 
       <Container fluid style={{paddingTop: 0, paddingBottom: 130, marginBottom: 130, position: 'relative', zIndex: 5}}>

         <Row style={{ maxWidth: 1280, margin: 'auto'}} data-aos="fade-up" data-aos-duration="750">
            
               
                   <p className="lead" style={{textAlign: 'center', margin: 'auto', marginBottom: 20, marginTop: 20}}>
                     
                   <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                   <div style={{backgroundColor: 'rgba(65, 62, 101, 0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                     {icon(props.icon1)}
                     {/* <FontAwesomeIcon icon={props.icon1} style={{color: '#413e65', marginTop: 14, marginLeft: 1}} /> */}
                     </div>
                   <h3 style={{fontFamily: 'Montserrat', color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title1}</h3>
                   </Button>
 
                   <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                   <div style={{backgroundColor: 'rgba(65,62,101,0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                   {icon(props.icon2)}
                     </div>
                   <h3 style={{fontFamily: 'Montserrat',color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title2}</h3>
                   </Button>
                   <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                   <div style={{backgroundColor: 'rgba(65, 62, 101, 0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                   {icon(props.icon3)}
                     </div>
                   <h3 style={{fontFamily: 'Montserrat',color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title3}</h3>
                   </Button>
                   <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                   <div style={{backgroundColor: 'rgba(65, 62, 101, 0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                   {icon(props.icon4)}
                     </div>
                   <h3 style={{fontFamily: 'Montserrat',color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title4}</h3>
                   </Button>
                   <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                   <div style={{backgroundColor: 'rgba(65, 62, 101, 0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                   {icon(props.icon5)}
                     </div>
                   <h3 style={{fontFamily: 'Montserrat',color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title5}</h3>
                   </Button>
                   <Button variant="outline-primary" size="lg" id='tabHover' onClick={goTo}>
                   <div style={{backgroundColor: 'rgba(65, 62, 101, 0.1)', borderRadius:'50%', marginRight: 16, width: 55, height: 55, minWidth: 55, fontSize: 28,float: 'left'}}>
                   {icon(props.icon6)}
                     </div>
                   <h3 style={{fontFamily: 'Montserrat',color: '#212529', fontSize: 21, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left', marginTop: 15,}}>   {props.title6}</h3>
                   </Button>
     
                   </p> 
           
               </Row>
  
       </Container>

      }
        
    {/* </Section> */}
        </div>
    )
}

export default Services