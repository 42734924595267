import React from 'react'
import { graphql } from "gatsby"
import Services from '../components/Pages/Services';
import Page from '../components/Pages/Page';

function ServicesTemplate(props) {
    
    return (
        <Page {...props} PageComponent={ Services } />
    )
}

export const queryServices = graphql`
fragment servicesFields on NewSites {
    ...globalFields
    pages {
        type
        id
        aUri
        content {
            h1
            subTitle
            text
            image
            images
            seoTitle
            seoDescription
            clickable
            service1
            service2
            service3
            service4
            service5
            service6
            serviceIcon1
            serviceIcon2
            serviceIcon3
            serviceIcon4
            serviceIcon5
            serviceIcon6
        }
    }
}
query servicesQuery($subDomain: String) {
    newSites(subDomain: {eq: $subDomain}) {
        ...servicesFields
    }
}`

export default ServicesTemplate


